$purple1: rgb(254, 249, 255);
$purple2: rgb(254, 227, 255);
$purple3: rgb(189, 144, 181);
$purple4: #1B0920;
$purple5: #35153d;
$purple6: #15031a;

$orange1: hsl(35, 100%, 97%);
$orange2: hsl(35, 65%, 94%);
$orange3: hsl(35, 68%, 90%);
$orange4: hsl(35, 92%, 76%);
$orange5: hsl(34, 58%, 50%);
$orange6: hsl(35, 86%, 8%);
$orange7: hsl(34, 100%, 3%);