@font-face {
    font-family: FiraSans;
    font-style: normal;
    src: url(~/src/fonts/FiraSans-Regular.otf);
}

@font-face {
    font-family: FiraSans;
    font-weight: bold;
    src: url(~/src/fonts/FiraSans-Bold.otf);
}

@font-face {
    font-family: Merriweather;
    font-weight: normal;
    src: url(~/src/fonts/Merriweather-Regular.otf);
}

@font-face {
    font-family: Merriweather;
    font-weight: bold;
    src: url(~/src/fonts/Merriweather-Bold.otf);
}