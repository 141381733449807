@use '_fonts';
@use '_colors' as c;
@use '_resets';
@use '_mixins' as m;

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

body {
    background: c.$orange2;
}

.wrapper {
    padding-left: 3rem;
    padding-right: 3rem;

    @include m.forMediumScreens {
        max-width: 1000px;
        margin: 0 auto;
    }
}

.header {
    background: linear-gradient(to bottom, c.$purple4, c.$purple5);
    height: 600px;
    text-align: center;
    clip-path: ellipse(200% 95% at 50% 0%);

    .header__title {
        padding: 4rem 0;

        h1 {
            background: linear-gradient(to bottom, c.$purple2, c.$purple3);
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            margin: 0 1rem;
            font-size: 3rem;

            strong {
                background: linear-gradient(to bottom, #fff, c.$purple3);
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
            }
        }
    }

    @keyframes backgroundXSlide {
        0% {
            background-position: 0px;
        }

        100% {
            background-position: -1579px;
        }
    }

    .header__orbCanvas {
        background-image: url(~/src/img/toolchainBg.png);
        background-repeat: repeat-x;
        animation: 20s linear 0s infinite both backgroundXSlide;

        @include m.forMediumScreens {
            $mask: linear-gradient(to right, transparent 10%, rgba(0, 0, 0, 1.0) 25%, rgba(0, 0, 0, 1.0) 75%, transparent 90%);
            mask-image: $mask;
            -webkit-mask-image: $mask;
        }
    }

    .header__orb,
    .header__orbWithGlow {
        &.header__orb {
            position: absolute;
        }

        &.header__orbWithGlow {
            opacity: 0;
            animation: 2s ease-in-out 0s infinite alternate fadeIn;
        }
    }
}

.sideSpacer {
    padding-left: 3rem;
    padding-right: 3rem;
}

.intro {
    color: c.$orange6;
    @extend .sideSpacer;

    .intro__wrapper {
        @extend .wrapper;

        position: relative;
        background: #fffefa;
        padding: 2rem 0;
        top: -4rem;
        border-radius: 5px;
        box-shadow: 1px 1px 2rem change-color(c.$orange7, $alpha: 0.25);

        .intro__title {
            padding: 1.25rem;
            font-size: 2.5rem;
            $hMargin: 1rem;
            margin-left: $hMargin;
            margin-right: $hMargin;

            >* {
                display: inline;
            }
        }

        .intro__description ul {
            margin: 0 1.5rem;

            strong {
                color: c.$orange7;
            }

            >li {
                margin: 1.5rem 0 1.5rem 1.75rem;
                font-size: 1.25rem;
            }
        }

        @include m.forLargeScreens {
            display: flex;
            padding: 5rem 0;

            >* {
                align-items: stretch;
            }

            .intro__title {
                min-width: 37%;
                text-align: right;

                >* {
                    display: block;
                }
            }
        }
    }
}

.skillListWrapper {
    .skillListWrapper__title {
        padding: 1rem 0;
    }

    padding-bottom: 3rem;

    @include m.forLargerScreens {
        padding-bottom: 3rem;
    }
}

.skillList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-family: Merriweather, Arial, Helvetica, sans-serif;
    justify-content: flex-start;

    $skillMargin: 1rem;

    >* {
        background-image: linear-gradient(to top, darken(c.$orange5, 10%), c.$orange5);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-basis: 100%;
        height: 10rem;
        font-size: 1.5rem;
        color: c.$orange1;
        padding: 1.25rem;
        margin-bottom: $skillMargin;
        box-shadow: 0rem 0rem 1rem change-color(c.$orange7, $alpha: 0.5);

        &:nth-child(2n + 1) {
            background-image: linear-gradient(to top, darken(c.$orange5, 15%), c.$orange5);
            box-shadow: 0rem 0rem 1rem change-color(c.$orange7, $alpha: 0.2);
        }

        img {
            width: 20%;
            max-width: 4rem;
            object-fit: contain;
        }
    }

    @include m.forMediumScreens {
        >* {
            flex-basis: 35%;
            flex-grow: 1;
            margin-right: $skillMargin;
        }
    }

    @include m.forLargerScreens {
        flex-wrap: nowrap;
        justify-content: center;

        >* {
            justify-content: space-between;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include m.forLargeScreens {
    .miscWrapper {
        display: flex;

        .miscWrapper__title {
            max-width: 20rem;
        }
    }
}

.miscWrapper {
    /* Disabled because Safari doesn't render the animation in it correctly
       and it was too annoying to fix. Revert this commit in its entirety if
       you want to make it visible again. */
    display: none;

    .miscWrapper__title {
        padding-top: 2rem;
        padding-right: 2rem;
        font-size: 2rem;
    }
}

.vikingWrapper {
    margin: 1rem 0 5rem 0;
    display: flex;

    .vikingWrapper__title {
        font-size: 1.5rem;
        color: c.$orange5;

        strong {
            color: darken(c.$orange5, 15%);
        }
    }

    >* {
        align-self: center;
    }

    .vikingWrapper__image {
        display: none;
    }

    @include m.forMediumScreens {
        .vikingWrapper__image {
            display: block;
            width: 4rem;
            margin-right: 2rem;
        }
    }
}

.animatedSkillList {
    @keyframes pulse {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.9;
        }

        100% {
            opacity: 1;
        }
    }

    overflow: hidden;
    $mask: linear-gradient(to bottom, transparent 5%, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 1) 40%,
            rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.2) 75%, transparent 95%);
    mask-image: $mask;
    -webkit-mask-image: $mask;
    height: 25rem;
    font-size: 2rem;
    position: relative;
    $animationLength: 25s;
    animation: pulse 2s linear infinite;

    li {
        list-style: none;
        padding-bottom: 1rem;
    }

    .animatedSkillList__layer1,
    animatedSkillList__layer2 {
        position: absolute;
    }

    .animatedSkillList__layer1 {
        @keyframes scroller1 {
            0% {
                transform: translateY(0%);
            }

            100% {
                transform: translateY(-100%);
            }
        }

        animation: scroller1 $animationLength linear infinite;
    }

    .animatedSkillList__layer2 {
        @keyframes scroller2 {
            0% {
                transform: translateY(100%);
            }

            100% {
                transform: translateY(0%);
            }
        }

        animation: scroller2 $animationLength linear infinite;
    }

    &.animatedSkillList--light {
        font-weight: bold;
        font-family: Merriweather;

        li {
            color: transparent;
            background: lighten(c.$orange6, 10%);
            background-clip: text;
            -webkit-background-clip: text;

            &:nth-child(2n) {
                color: saturate(lighten(c.$orange6, 5%), 10%);
            }
        }
    }
}

.footer {
    padding: 7rem 0 4rem;
    background: url(~/src/img/squircleBg.png) 150% 60% no-repeat c.$purple6;
    background-size: 50%;
    color: c.$purple1;

    .footer__title {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .footer__disclaimer {
        margin-top: 2rem;
        color: c.$purple3;
        font-size: 1rem;
        line-height: 1.25rem;
        max-width: 80%;

        @include m.forMediumScreens {
            max-width: 60%;
        }
    }
}

.copyright {
    background: darken(c.$purple6, 2%);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: solid 1px change-color(c.$purple3, $alpha: 0.1);

    .copyright__aligner {
        filter: brightness(1.5);
        color: #3b2a3f;
        display: flex;
        gap: 1rem;
        align-items: center;

        .copyright__icon {
            width: 2.25rem;
        }
    }
}

.contactOptions {
    display: flex;

    .contactOption {
        width: 4rem;
        margin-right: 1rem;

        >* {
            width: 100%;
            filter: brightness(1.05);
        }

        &:hover {
            transform: scale(110%);
            transition: transform 0.1s ease-in;
        }
    }
}
