@font-face {
  font-family: FiraSans;
  font-style: normal;
  src: url("FiraSans-Regular.7e456090.otf");
}

@font-face {
  font-family: FiraSans;
  font-weight: bold;
  src: url("FiraSans-Bold.83722eaf.otf");
}

@font-face {
  font-family: Merriweather;
  font-weight: normal;
  src: url("Merriweather-Regular.5b0b1dc4.otf");
}

@font-face {
  font-family: Merriweather;
  font-weight: bold;
  src: url("Merriweather-Bold.c9a7c10a.otf");
}

html {
  font-family: FiraSans, Arial, Helvetica, sans-serif;
  font-size: 14px;
}

@media screen and (min-width: 800px) {
  html {
    font-size: 16px;
  }
}

body, h1, h2, h3, h4, h5, ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

a > img {
  border: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  background: #faf1e6;
}

.wrapper, .intro .intro__wrapper {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (min-width: 500px) {
  .wrapper, .intro .intro__wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.header {
  height: 600px;
  text-align: center;
  clip-path: ellipse(200% 95% at 50% 0);
  background: linear-gradient(#1b0920, #35153d);
}

.header .header__title {
  padding: 4rem 0;
}

.header .header__title h1 {
  color: #0000;
  background: linear-gradient(#fee3ff, #bd90b5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  margin: 0 1rem;
  font-size: 3rem;
}

.header .header__title h1 strong {
  color: #0000;
  background: linear-gradient(#fff, #bd90b5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
}

@keyframes backgroundXSlide {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -1579px;
  }
}

.header .header__orbCanvas {
  background-image: url("toolchainBg.1985620d.png");
  background-repeat: repeat-x;
  animation: backgroundXSlide 20s linear infinite both;
}

@media screen and (min-width: 500px) {
  .header .header__orbCanvas {
    -webkit-mask-image: linear-gradient(to right, #0000 10%, #000 25% 75%, #0000 90%);
    mask-image: linear-gradient(to right, #0000 10%, #000 25% 75%, #0000 90%);
  }
}

.header .header__orb.header__orb, .header .header__orbWithGlow.header__orb {
  position: absolute;
}

.header .header__orb.header__orbWithGlow, .header .header__orbWithGlow.header__orbWithGlow {
  opacity: 0;
  animation: fadeIn 2s ease-in-out infinite alternate;
}

.sideSpacer, .intro {
  padding-left: 3rem;
  padding-right: 3rem;
}

.intro {
  color: #261703;
}

.intro .intro__wrapper {
  background: #fffefa;
  border-radius: 5px;
  padding: 2rem 0;
  position: relative;
  top: -4rem;
  box-shadow: 1px 1px 2rem #0f090040;
}

.intro .intro__wrapper .intro__title {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1.25rem;
  font-size: 2.5rem;
}

.intro .intro__wrapper .intro__title > * {
  display: inline;
}

.intro .intro__wrapper .intro__description ul {
  margin: 0 1.5rem;
}

.intro .intro__wrapper .intro__description ul strong {
  color: #0f0900;
}

.intro .intro__wrapper .intro__description ul > li {
  margin: 1.5rem 0 1.5rem 1.75rem;
  font-size: 1.25rem;
}

@media screen and (min-width: 800px) {
  .intro .intro__wrapper {
    padding: 5rem 0;
    display: flex;
  }

  .intro .intro__wrapper > * {
    align-items: stretch;
  }

  .intro .intro__wrapper .intro__title {
    min-width: 37%;
    text-align: right;
  }

  .intro .intro__wrapper .intro__title > * {
    display: block;
  }
}

.skillListWrapper {
  padding-bottom: 3rem;
}

.skillListWrapper .skillListWrapper__title {
  padding: 1rem 0;
}

@media screen and (min-width: 1000px) {
  .skillListWrapper {
    padding-bottom: 3rem;
  }
}

.skillList {
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: Merriweather, Arial, Helvetica, sans-serif;
  list-style: none;
  display: flex;
}

.skillList > * {
  height: 10rem;
  color: #fff9f0;
  background-image: linear-gradient(to top, #a16e2b, #c98936);
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1.25rem;
  font-size: 1.5rem;
  display: flex;
  box-shadow: 0 0 1rem #0f090080;
}

.skillList > :nth-child(2n+1) {
  background-image: linear-gradient(to top, #8d6025, #c98936);
  box-shadow: 0 0 1rem #0f090033;
}

.skillList > * img {
  width: 20%;
  max-width: 4rem;
  object-fit: contain;
}

@media screen and (min-width: 500px) {
  .skillList > * {
    flex-grow: 1;
    flex-basis: 35%;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1000px) {
  .skillList {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .skillList > * {
    justify-content: space-between;
  }

  .skillList > :last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 800px) {
  .miscWrapper {
    display: flex;
  }

  .miscWrapper .miscWrapper__title {
    max-width: 20rem;
  }
}

.miscWrapper {
  display: none;
}

.miscWrapper .miscWrapper__title {
  padding-top: 2rem;
  padding-right: 2rem;
  font-size: 2rem;
}

.vikingWrapper {
  margin: 1rem 0 5rem;
  display: flex;
}

.vikingWrapper .vikingWrapper__title {
  color: #c98936;
  font-size: 1.5rem;
}

.vikingWrapper .vikingWrapper__title strong {
  color: #8d6025;
}

.vikingWrapper > * {
  align-self: center;
}

.vikingWrapper .vikingWrapper__image {
  display: none;
}

@media screen and (min-width: 500px) {
  .vikingWrapper .vikingWrapper__image {
    width: 4rem;
    margin-right: 2rem;
    display: block;
  }
}

.animatedSkillList {
  height: 25rem;
  font-size: 2rem;
  animation: pulse 2s linear infinite;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(#0000 5%, #0003 25%, #000 40% 60%, #0003 75%, #0000 95%);
  mask-image: linear-gradient(#0000 5%, #0003 25%, #000 40% 60%, #0003 75%, #0000 95%);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .9;
  }

  100% {
    opacity: 1;
  }
}

.animatedSkillList li {
  padding-bottom: 1rem;
  list-style: none;
}

.animatedSkillList .animatedSkillList__layer1, .animatedSkillList animatedSkillList__layer2 {
  position: absolute;
}

.animatedSkillList .animatedSkillList__layer1 {
  animation: scroller1 25s linear infinite;
}

@keyframes scroller1 {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.animatedSkillList .animatedSkillList__layer2 {
  animation: scroller2 25s linear infinite;
}

@keyframes scroller2 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.animatedSkillList.animatedSkillList--light {
  font-family: Merriweather;
  font-weight: bold;
}

.animatedSkillList.animatedSkillList--light li {
  color: #0000;
  background: #553406;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
}

.animatedSkillList.animatedSkillList--light li:nth-child(2n) {
  color: #412601;
}

.footer {
  color: #fef9ff;
  background: #15031a url("squircleBg.8fd3f66a.png") 150% 60% / 50% no-repeat;
  padding: 7rem 0 4rem;
}

.footer .footer__title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.footer .footer__disclaimer {
  color: #bd90b5;
  max-width: 80%;
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.25rem;
}

@media screen and (min-width: 500px) {
  .footer .footer__disclaimer {
    max-width: 60%;
  }
}

.copyright {
  background: #0e0211;
  border-top: 1px solid #bd90b51a;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.copyright .copyright__aligner {
  filter: brightness(1.5);
  color: #3b2a3f;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.copyright .copyright__aligner .copyright__icon {
  width: 2.25rem;
}

.contactOptions {
  display: flex;
}

.contactOptions .contactOption {
  width: 4rem;
  margin-right: 1rem;
}

.contactOptions .contactOption > * {
  width: 100%;
  filter: brightness(1.05);
}

.contactOptions .contactOption:hover {
  transition: transform .1s ease-in;
  transform: scale(1.1);
}

/*# sourceMappingURL=index.7ebd22e4.css.map */
