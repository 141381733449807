@use '_mixins' as m;

html {
    font-size: 14px;
    font-family: FiraSans, Arial, Helvetica, sans-serif;

    @include m.forLargeScreens {
        font-size: 16px;
    }
}

body, h1, h2, h3, h4, h5, ul {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
}

a > img {
    border: none;
}