@mixin forMediumScreens {
    @media screen and (min-width: 500px) {
        @content;
    }
}

@mixin forLargeScreens {
    @media screen and (min-width: 800px) {
        @content;
    }
}

@mixin forLargerScreens {
    @media screen and (min-width: 1000px) {
        @content;
    }
}